* {
    box-shadow: border-box;
    margin: 0;
    padding: 0;
}

html {
    scroll-behaviour: smooth;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.brdImg {
    -webkit-filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white);
    filter: drop-shadow(1px 1px 0 white) drop-shadow(-1px -1px 0 white);
}

@layer base {
    :root {
        --background: 0 0% 100%;
        --foreground: 222.2 84% 4.9%;

        --card: 0 0% 100%;
        --card-foreground: 222.2 84% 4.9%;

        --popover: 0 0% 100%;
        --popover-foreground: 222.2 84% 4.9%;

        --primary: 222.2 47.4% 11.2%;
        --primary-foreground: 210 40% 98%;

        --secondary: 210 40% 96.1%;
        --secondary-foreground: 222.2 47.4% 11.2%;

        --muted: 210 40% 96.1%;
        --muted-foreground: 215.4 16.3% 46.9%;

        --accent: 210 40% 96.1%;
        --accent-foreground: 222.2 47.4% 11.2%;

        --destructive: 0 84.2% 60.2%;
        --destructive-foreground: 210 40% 98%;

        --border: 214.3 31.8% 91.4%;
        --input: 214.3 31.8% 91.4%;
        --ring: 222.2 84% 4.9%;

        --radius: 0.5rem;
    }

    .dark {
        --background: 222.2 84% 4.9%;
        --foreground: 210 40% 98%;

        --card: 222.2 84% 4.9%;
        --card-foreground: 210 40% 98%;

        --popover: 222.2 84% 4.9%;
        --popover-foreground: 210 40% 98%;

        --primary: 210 40% 98%;
        --primary-foreground: 222.2 47.4% 11.2%;

        --secondary: 217.2 32.6% 17.5%;
        --secondary-foreground: 210 40% 98%;

        --muted: 217.2 32.6% 17.5%;
        --muted-foreground: 215 20.2% 65.1%;

        --accent: 217.2 32.6% 17.5%;
        --accent-foreground: 210 40% 98%;

        --destructive: 0 62.8% 30.6%;
        --destructive-foreground: 210 40% 98%;

        --border: 217.2 32.6% 17.5%;
        --input: 217.2 32.6% 17.5%;
        --ring: 212.7 26.8% 83.9%;
    }
}

@layer base {
    * {
        @apply border-border;
    }

    body {
        @apply bg-background text-foreground;
    }
}

.nShadow {
    box-shadow: 20px 20px 50px #ececec, -30px -30px 60px #f8f8f8;
}

.zoom-in {
    @apply transition duration-300 ease-in-out transform cursor-pointer;
}

.zoom-in:hover {
    @apply scale-105 shadow-xl;
}

*>.intro-x:nth-child(1) {
    z-index: 49;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

*>.-intro-x:nth-child(1) {
    z-index: 49;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

*>.intro-y:nth-child(1) {
    z-index: 49;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

*>.-intro-y:nth-child(1) {
    z-index: 49;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s;
}

*>.intro-x:nth-child(2) {
    z-index: 48;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

*>.-intro-x:nth-child(2) {
    z-index: 48;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

*>.intro-y:nth-child(2) {
    z-index: 48;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

*>.-intro-y:nth-child(2) {
    z-index: 48;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s;
}

*>.intro-x:nth-child(3) {
    z-index: 47;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

*>.-intro-x:nth-child(3) {
    z-index: 47;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

*>.intro-y:nth-child(3) {
    z-index: 47;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

*>.-intro-y:nth-child(3) {
    z-index: 47;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s;
}

*>.intro-x:nth-child(4) {
    z-index: 46;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

*>.-intro-x:nth-child(4) {
    z-index: 46;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

*>.intro-y:nth-child(4) {
    z-index: 46;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

*>.-intro-y:nth-child(4) {
    z-index: 46;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s;
}

*>.intro-x:nth-child(5) {
    z-index: 45;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

*>.-intro-x:nth-child(5) {
    z-index: 45;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

*>.intro-y:nth-child(5) {
    z-index: 45;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

*>.-intro-y:nth-child(5) {
    z-index: 45;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
}

*>.intro-x:nth-child(6) {
    z-index: 44;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

*>.-intro-x:nth-child(6) {
    z-index: 44;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

*>.intro-y:nth-child(6) {
    z-index: 44;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

*>.-intro-y:nth-child(6) {
    z-index: 44;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.6s;
    animation-delay: 0.6s;
}

*>.intro-x:nth-child(7) {
    z-index: 43;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}

*>.-intro-x:nth-child(7) {
    z-index: 43;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}

*>.intro-y:nth-child(7) {
    z-index: 43;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}

*>.-intro-y:nth-child(7) {
    z-index: 43;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.7s;
    animation-delay: 0.7s;
}

*>.intro-x:nth-child(8) {
    z-index: 42;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

*>.-intro-x:nth-child(8) {
    z-index: 42;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

*>.intro-y:nth-child(8) {
    z-index: 42;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

*>.-intro-y:nth-child(8) {
    z-index: 42;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.8s;
    animation-delay: 0.8s;
}

*>.intro-x:nth-child(9) {
    z-index: 41;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

*>.-intro-x:nth-child(9) {
    z-index: 41;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

*>.intro-y:nth-child(9) {
    z-index: 41;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

*>.-intro-y:nth-child(9) {
    z-index: 41;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 0.9s;
    animation-delay: 0.9s;
}

*>.intro-x:nth-child(10) {
    z-index: 40;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

*>.-intro-x:nth-child(10) {
    z-index: 40;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

*>.intro-y:nth-child(10) {
    z-index: 40;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

*>.-intro-y:nth-child(10) {
    z-index: 40;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1s;
    animation-delay: 1s;
}

*>.intro-x:nth-child(11) {
    z-index: 39;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
}

*>.-intro-x:nth-child(11) {
    z-index: 39;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
}

*>.intro-y:nth-child(11) {
    z-index: 39;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
}

*>.-intro-y:nth-child(11) {
    z-index: 39;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.1s;
    animation-delay: 1.1s;
}

*>.intro-x:nth-child(12) {
    z-index: 38;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

*>.-intro-x:nth-child(12) {
    z-index: 38;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

*>.intro-y:nth-child(12) {
    z-index: 38;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

*>.-intro-y:nth-child(12) {
    z-index: 38;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.2s;
    animation-delay: 1.2s;
}

*>.intro-x:nth-child(13) {
    z-index: 37;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
}

*>.-intro-x:nth-child(13) {
    z-index: 37;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
}

*>.intro-y:nth-child(13) {
    z-index: 37;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
}

*>.-intro-y:nth-child(13) {
    z-index: 37;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.3s;
    animation-delay: 1.3s;
}

*>.intro-x:nth-child(14) {
    z-index: 36;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
}

*>.-intro-x:nth-child(14) {
    z-index: 36;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
}

*>.intro-y:nth-child(14) {
    z-index: 36;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
}

*>.-intro-y:nth-child(14) {
    z-index: 36;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.4s;
    animation-delay: 1.4s;
}

*>.intro-x:nth-child(15) {
    z-index: 35;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

*>.-intro-x:nth-child(15) {
    z-index: 35;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

*>.intro-y:nth-child(15) {
    z-index: 35;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

*>.-intro-y:nth-child(15) {
    z-index: 35;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.5s;
    animation-delay: 1.5s;
}

*>.intro-x:nth-child(16) {
    z-index: 34;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
}

*>.-intro-x:nth-child(16) {
    z-index: 34;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
}

*>.intro-y:nth-child(16) {
    z-index: 34;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
}

*>.-intro-y:nth-child(16) {
    z-index: 34;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.6s;
    animation-delay: 1.6s;
}

*>.intro-x:nth-child(17) {
    z-index: 33;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.7s;
    animation-delay: 1.7s;
}

*>.-intro-x:nth-child(17) {
    z-index: 33;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.7s;
    animation-delay: 1.7s;
}

*>.intro-y:nth-child(17) {
    z-index: 33;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.7s;
    animation-delay: 1.7s;
}

*>.-intro-y:nth-child(17) {
    z-index: 33;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.7s;
    animation-delay: 1.7s;
}

*>.intro-x:nth-child(18) {
    z-index: 32;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
}

*>.-intro-x:nth-child(18) {
    z-index: 32;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
}

*>.intro-y:nth-child(18) {
    z-index: 32;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
}

*>.-intro-y:nth-child(18) {
    z-index: 32;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.8s;
    animation-delay: 1.8s;
}

*>.intro-x:nth-child(19) {
    z-index: 31;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.9s;
    animation-delay: 1.9s;
}

*>.-intro-x:nth-child(19) {
    z-index: 31;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.9s;
    animation-delay: 1.9s;
}

*>.intro-y:nth-child(19) {
    z-index: 31;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.9s;
    animation-delay: 1.9s;
}

*>.-intro-y:nth-child(19) {
    z-index: 31;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 1.9s;
    animation-delay: 1.9s;
}

*>.intro-x:nth-child(20) {
    z-index: 30;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

*>.-intro-x:nth-child(20) {
    z-index: 30;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

*>.intro-y:nth-child(20) {
    z-index: 30;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

*>.-intro-y:nth-child(20) {
    z-index: 30;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2s;
    animation-delay: 2s;
}

*>.intro-x:nth-child(21) {
    z-index: 29;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.1s;
    animation-delay: 2.1s;
}

*>.-intro-x:nth-child(21) {
    z-index: 29;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.1s;
    animation-delay: 2.1s;
}

*>.intro-y:nth-child(21) {
    z-index: 29;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.1s;
    animation-delay: 2.1s;
}

*>.-intro-y:nth-child(21) {
    z-index: 29;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.1s;
    animation-delay: 2.1s;
}

*>.intro-x:nth-child(22) {
    z-index: 28;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.2s;
    animation-delay: 2.2s;
}

*>.-intro-x:nth-child(22) {
    z-index: 28;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.2s;
    animation-delay: 2.2s;
}

*>.intro-y:nth-child(22) {
    z-index: 28;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.2s;
    animation-delay: 2.2s;
}

*>.-intro-y:nth-child(22) {
    z-index: 28;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.2s;
    animation-delay: 2.2s;
}

*>.intro-x:nth-child(23) {
    z-index: 27;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.3s;
    animation-delay: 2.3s;
}

*>.-intro-x:nth-child(23) {
    z-index: 27;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.3s;
    animation-delay: 2.3s;
}

*>.intro-y:nth-child(23) {
    z-index: 27;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.3s;
    animation-delay: 2.3s;
}

*>.-intro-y:nth-child(23) {
    z-index: 27;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.3s;
    animation-delay: 2.3s;
}

*>.intro-x:nth-child(24) {
    z-index: 26;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.4s;
    animation-delay: 2.4s;
}

*>.-intro-x:nth-child(24) {
    z-index: 26;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.4s;
    animation-delay: 2.4s;
}

*>.intro-y:nth-child(24) {
    z-index: 26;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.4s;
    animation-delay: 2.4s;
}

*>.-intro-y:nth-child(24) {
    z-index: 26;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.4s;
    animation-delay: 2.4s;
}

*>.intro-x:nth-child(25) {
    z-index: 25;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

*>.-intro-x:nth-child(25) {
    z-index: 25;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

*>.intro-y:nth-child(25) {
    z-index: 25;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

*>.-intro-y:nth-child(25) {
    z-index: 25;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.5s;
    animation-delay: 2.5s;
}

*>.intro-x:nth-child(26) {
    z-index: 24;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.6s;
    animation-delay: 2.6s;
}

*>.-intro-x:nth-child(26) {
    z-index: 24;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.6s;
    animation-delay: 2.6s;
}

*>.intro-y:nth-child(26) {
    z-index: 24;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.6s;
    animation-delay: 2.6s;
}

*>.-intro-y:nth-child(26) {
    z-index: 24;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.6s;
    animation-delay: 2.6s;
}

*>.intro-x:nth-child(27) {
    z-index: 23;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.7s;
    animation-delay: 2.7s;
}

*>.-intro-x:nth-child(27) {
    z-index: 23;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.7s;
    animation-delay: 2.7s;
}

*>.intro-y:nth-child(27) {
    z-index: 23;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.7s;
    animation-delay: 2.7s;
}

*>.-intro-y:nth-child(27) {
    z-index: 23;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.7s;
    animation-delay: 2.7s;
}

*>.intro-x:nth-child(28) {
    z-index: 22;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.8s;
    animation-delay: 2.8s;
}

*>.-intro-x:nth-child(28) {
    z-index: 22;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.8s;
    animation-delay: 2.8s;
}

*>.intro-y:nth-child(28) {
    z-index: 22;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.8s;
    animation-delay: 2.8s;
}

*>.-intro-y:nth-child(28) {
    z-index: 22;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.8s;
    animation-delay: 2.8s;
}

*>.intro-x:nth-child(29) {
    z-index: 21;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.9s;
    animation-delay: 2.9s;
}

*>.-intro-x:nth-child(29) {
    z-index: 21;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.9s;
    animation-delay: 2.9s;
}

*>.intro-y:nth-child(29) {
    z-index: 21;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.9s;
    animation-delay: 2.9s;
}

*>.-intro-y:nth-child(29) {
    z-index: 21;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 2.9s;
    animation-delay: 2.9s;
}

*>.intro-x:nth-child(30) {
    z-index: 20;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

*>.-intro-x:nth-child(30) {
    z-index: 20;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

*>.intro-y:nth-child(30) {
    z-index: 20;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

*>.-intro-y:nth-child(30) {
    z-index: 20;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3s;
    animation-delay: 3s;
}

*>.intro-x:nth-child(31) {
    z-index: 19;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.1s;
    animation-delay: 3.1s;
}

*>.-intro-x:nth-child(31) {
    z-index: 19;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.1s;
    animation-delay: 3.1s;
}

*>.intro-y:nth-child(31) {
    z-index: 19;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.1s;
    animation-delay: 3.1s;
}

*>.-intro-y:nth-child(31) {
    z-index: 19;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.1s;
    animation-delay: 3.1s;
}

*>.intro-x:nth-child(32) {
    z-index: 18;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.2s;
    animation-delay: 3.2s;
}

*>.-intro-x:nth-child(32) {
    z-index: 18;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.2s;
    animation-delay: 3.2s;
}

*>.intro-y:nth-child(32) {
    z-index: 18;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.2s;
    animation-delay: 3.2s;
}

*>.-intro-y:nth-child(32) {
    z-index: 18;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.2s;
    animation-delay: 3.2s;
}

*>.intro-x:nth-child(33) {
    z-index: 17;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.3s;
    animation-delay: 3.3s;
}

*>.-intro-x:nth-child(33) {
    z-index: 17;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.3s;
    animation-delay: 3.3s;
}

*>.intro-y:nth-child(33) {
    z-index: 17;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.3s;
    animation-delay: 3.3s;
}

*>.-intro-y:nth-child(33) {
    z-index: 17;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.3s;
    animation-delay: 3.3s;
}

*>.intro-x:nth-child(34) {
    z-index: 16;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.4s;
    animation-delay: 3.4s;
}

*>.-intro-x:nth-child(34) {
    z-index: 16;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.4s;
    animation-delay: 3.4s;
}

*>.intro-y:nth-child(34) {
    z-index: 16;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.4s;
    animation-delay: 3.4s;
}

*>.-intro-y:nth-child(34) {
    z-index: 16;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.4s;
    animation-delay: 3.4s;
}

*>.intro-x:nth-child(35) {
    z-index: 15;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
}

*>.-intro-x:nth-child(35) {
    z-index: 15;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
}

*>.intro-y:nth-child(35) {
    z-index: 15;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
}

*>.-intro-y:nth-child(35) {
    z-index: 15;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.5s;
    animation-delay: 3.5s;
}

*>.intro-x:nth-child(36) {
    z-index: 14;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.6s;
    animation-delay: 3.6s;
}

*>.-intro-x:nth-child(36) {
    z-index: 14;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.6s;
    animation-delay: 3.6s;
}

*>.intro-y:nth-child(36) {
    z-index: 14;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.6s;
    animation-delay: 3.6s;
}

*>.-intro-y:nth-child(36) {
    z-index: 14;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.6s;
    animation-delay: 3.6s;
}

*>.intro-x:nth-child(37) {
    z-index: 13;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.7s;
    animation-delay: 3.7s;
}

*>.-intro-x:nth-child(37) {
    z-index: 13;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.7s;
    animation-delay: 3.7s;
}

*>.intro-y:nth-child(37) {
    z-index: 13;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.7s;
    animation-delay: 3.7s;
}

*>.-intro-y:nth-child(37) {
    z-index: 13;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.7s;
    animation-delay: 3.7s;
}

*>.intro-x:nth-child(38) {
    z-index: 12;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.8s;
    animation-delay: 3.8s;
}

*>.-intro-x:nth-child(38) {
    z-index: 12;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.8s;
    animation-delay: 3.8s;
}

*>.intro-y:nth-child(38) {
    z-index: 12;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.8s;
    animation-delay: 3.8s;
}

*>.-intro-y:nth-child(38) {
    z-index: 12;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.8s;
    animation-delay: 3.8s;
}

*>.intro-x:nth-child(39) {
    z-index: 11;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.9s;
    animation-delay: 3.9s;
}

*>.-intro-x:nth-child(39) {
    z-index: 11;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.9s;
    animation-delay: 3.9s;
}

*>.intro-y:nth-child(39) {
    z-index: 11;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.9s;
    animation-delay: 3.9s;
}

*>.-intro-y:nth-child(39) {
    z-index: 11;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 3.9s;
    animation-delay: 3.9s;
}

*>.intro-x:nth-child(40) {
    z-index: 10;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

*>.-intro-x:nth-child(40) {
    z-index: 10;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

*>.intro-y:nth-child(40) {
    z-index: 10;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

*>.-intro-y:nth-child(40) {
    z-index: 10;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4s;
    animation-delay: 4s;
}

*>.intro-x:nth-child(41) {
    z-index: 9;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.1s;
    animation-delay: 4.1s;
}

*>.-intro-x:nth-child(41) {
    z-index: 9;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.1s;
    animation-delay: 4.1s;
}

*>.intro-y:nth-child(41) {
    z-index: 9;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.1s;
    animation-delay: 4.1s;
}

*>.-intro-y:nth-child(41) {
    z-index: 9;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.1s;
    animation-delay: 4.1s;
}

*>.intro-x:nth-child(42) {
    z-index: 8;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.2s;
    animation-delay: 4.2s;
}

*>.-intro-x:nth-child(42) {
    z-index: 8;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.2s;
    animation-delay: 4.2s;
}

*>.intro-y:nth-child(42) {
    z-index: 8;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.2s;
    animation-delay: 4.2s;
}

*>.-intro-y:nth-child(42) {
    z-index: 8;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.2s;
    animation-delay: 4.2s;
}

*>.intro-x:nth-child(43) {
    z-index: 7;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.3s;
    animation-delay: 4.3s;
}

*>.-intro-x:nth-child(43) {
    z-index: 7;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.3s;
    animation-delay: 4.3s;
}

*>.intro-y:nth-child(43) {
    z-index: 7;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.3s;
    animation-delay: 4.3s;
}

*>.-intro-y:nth-child(43) {
    z-index: 7;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.3s;
    animation-delay: 4.3s;
}

*>.intro-x:nth-child(44) {
    z-index: 6;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.4s;
    animation-delay: 4.4s;
}

*>.-intro-x:nth-child(44) {
    z-index: 6;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.4s;
    animation-delay: 4.4s;
}

*>.intro-y:nth-child(44) {
    z-index: 6;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.4s;
    animation-delay: 4.4s;
}

*>.-intro-y:nth-child(44) {
    z-index: 6;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.4s;
    animation-delay: 4.4s;
}

*>.intro-x:nth-child(45) {
    z-index: 5;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.5s;
    animation-delay: 4.5s;
}

*>.-intro-x:nth-child(45) {
    z-index: 5;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.5s;
    animation-delay: 4.5s;
}

*>.intro-y:nth-child(45) {
    z-index: 5;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.5s;
    animation-delay: 4.5s;
}

*>.-intro-y:nth-child(45) {
    z-index: 5;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.5s;
    animation-delay: 4.5s;
}

*>.intro-x:nth-child(46) {
    z-index: 4;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.6s;
    animation-delay: 4.6s;
}

*>.-intro-x:nth-child(46) {
    z-index: 4;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.6s;
    animation-delay: 4.6s;
}

*>.intro-y:nth-child(46) {
    z-index: 4;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.6s;
    animation-delay: 4.6s;
}

*>.-intro-y:nth-child(46) {
    z-index: 4;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.6s;
    animation-delay: 4.6s;
}

*>.intro-x:nth-child(47) {
    z-index: 3;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.7s;
    animation-delay: 4.7s;
}

*>.-intro-x:nth-child(47) {
    z-index: 3;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.7s;
    animation-delay: 4.7s;
}

*>.intro-y:nth-child(47) {
    z-index: 3;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.7s;
    animation-delay: 4.7s;
}

*>.-intro-y:nth-child(47) {
    z-index: 3;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.7s;
    animation-delay: 4.7s;
}

*>.intro-x:nth-child(48) {
    z-index: 2;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.8s;
    animation-delay: 4.8s;
}

*>.-intro-x:nth-child(48) {
    z-index: 2;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.8s;
    animation-delay: 4.8s;
}

*>.intro-y:nth-child(48) {
    z-index: 2;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.8s;
    animation-delay: 4.8s;
}

*>.-intro-y:nth-child(48) {
    z-index: 2;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.8s;
    animation-delay: 4.8s;
}

*>.intro-x:nth-child(49) {
    z-index: 1;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.9s;
    animation-delay: 4.9s;
}

*>.-intro-x:nth-child(49) {
    z-index: 1;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.9s;
    animation-delay: 4.9s;
}

*>.intro-y:nth-child(49) {
    z-index: 1;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.9s;
    animation-delay: 4.9s;
}

*>.-intro-y:nth-child(49) {
    z-index: 1;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 4.9s;
    animation-delay: 4.9s;
}

*>.intro-x:nth-child(50) {
    z-index: 0;
    @apply opacity-0 relative;
    -webkit-transform: translateX(50px);
    transform: translateX(50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}

*>.-intro-x:nth-child(50) {
    z-index: 0;
    @apply opacity-0 relative;
    -webkit-transform: translateX(-50px);
    transform: translateX(-50px);
    -webkit-animation: 0.4s intro-x-animation ease-in-out .33333s;
    animation: 0.4s intro-x-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}

*>.intro-y:nth-child(50) {
    z-index: 0;
    @apply opacity-0 relative;
    -webkit-transform: translateY(50px);
    transform: translateY(50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}

*>.-intro-y:nth-child(50) {
    z-index: 0;
    @apply opacity-0 relative;
    -webkit-transform: translateY(-50px);
    transform: translateY(-50px);
    -webkit-animation: 0.4s intro-y-animation ease-in-out .33333s;
    animation: 0.4s intro-y-animation ease-in-out .33333s;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
    -webkit-animation-delay: 5s;
    animation-delay: 5s;
}

@-webkit-keyframes intro-x-animation {
    100% {
        @apply opacity-100;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@keyframes intro-x-animation {
    100% {
        @apply opacity-100;
        -webkit-transform: translateX(0px);
        transform: translateX(0px);
    }
}

@-webkit-keyframes intro-y-animation {
    100% {
        @apply opacity-100;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

@keyframes intro-y-animation {
    100% {
        @apply opacity-100;
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
    }
}

/* Mobile styles */
@media (max-width: 480px) {
    .bg-login {
        background-size: 40%;
    }
}